import React from "react";
import Layout from "../../containers/layout";
import SEO from "../../components/seo";
import { graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";


import * as styles from "../../styles/pages/contacts.module.css";
import * as sharedStyles from "../../styles/shared.module.css";

export const query = graphql`
  query ContactsPageQueryEn {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      surgeonName: surgeonName_en
      title: title_en
      description: description_en
      keywords: keywords_en
    }
  }
`;

const IndexPage = props => {
  const { data } = props;

  const site = data?.site;

  return (
    <Layout isEn>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div style={{ display: 'flex' }}>
        <h2 className={sharedStyles.sectionTitle}>Contacts</h2>
      </div>
      <div className={styles.main}>
        <div className={styles.sectionItem}>
          <h4 className={styles.sectionTitle}>
            Clinic adresses
          </h4>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../../media/map.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5>Hartman Clinic</h5>
                <p>Moscow, Myasnitskaya St, 19</p>
              </div>
              <div className={styles.sectionItemDesc}>
                <p>Google Maps: <a rel="noopener noreferrer" target="_blank" href="https://g.co/kgs/yDGTFdN">g.co/kgs/yDGTFdN</a></p>
              </div>
            </div>
          </div>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../../media/map.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5>Klinika Kozhi</h5>
                <p>Moscow, Banniy alley, 2с2</p>
              </div>
              <div className={styles.sectionItemDesc}>
                <p>Website: <a rel="noopener noreferrer" target="_blank" href="https://dermclinic.ru">dermclinic.ru</a></p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionItem}>
          <h4 className={styles.sectionTitle}>
            My contacts
          </h4>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../../media/email.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5><a href="mailto:Viktoriaa@yandex.ru">Viktoriaa@yandex.ru</a></h5>
              </div>
            </div>
          </div>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../../media/social.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5><a href="tel:+79263951759">+7 (926) 395-17-59</a></h5>
                <p>Got questions or want to book an appointment? Send me a message on WhatsApp</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionItem}>
          <h4 className={styles.sectionTitle}>
            Socials
          </h4>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../../media/inst.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5><a rel="noopener noreferrer" target="_blank" href="https://instagram.com/dr.andreevavv/">dr.andreevavv</a></h5>
                <h5><a rel="noopener noreferrer" className="asterisk" target="_blank" href="https://www.instagram.com/dr.viktoriaaplastic">dr.viktoriaaplastic</a></h5>
              </div>
            </div>
          </div> 
        </div>
      </div>
      {/* <div className={sharedStyles.transitionMargin}>
        <StaticImage src="../media/BackgroundShift.svg" objectFit="contain" alt="Background transition" className={sharedStyles.backgroundTransition} />
      </div> */}
    </Layout>
  );
};

export default IndexPage;
